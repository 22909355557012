export type DynamicValue = boolean | number | object | string | undefined;

export function isProduction(): boolean;
export function isProduction<T extends DynamicValue>(value: T): T | undefined;
/**
 * Checks if the current environment is production
 * @param value - If passed, it will return this value only on production environments
 */
export function isProduction<T extends DynamicValue>(value?: T): T | boolean | undefined {
	const isProd = process.env.NODE_ENV === 'production';
	if (value !== undefined) {
		return isProd ? value : undefined;
	}

	return isProd;
}

export function isDevelopment(): boolean;
export function isDevelopment<T extends DynamicValue>(value: T): T | undefined;
/**
 * Checks if the current environment is development
 * @param value - If passed, it will return this value only on development environments
 */
export function isDevelopment<T extends DynamicValue>(value?: T): T | boolean | undefined {
	const isDev = process.env.NODE_ENV !== 'production';
	if (value !== undefined) {
		return isDev ? value : undefined;
	}

	return isDev;
}
