import { type ClassValue, clsx } from 'clsx';
import { flow } from 'fp-ts/function';
import { extendTailwindMerge, validators } from 'tailwind-merge';

import { devWarn } from '../logs/logs';

export const singleArgWarningMessage =
	"It seems like you're using the cn function with a single string input. The cn function is designed for handling conditional classes. If you're not dealing with conditional classes, consider using the class directly.";

export const textColorValidator = (classname: string): boolean => !classname.startsWith('size');

export const twMergeConfig = {
	override: {
		classGroups: {
			'font-size': [{ 'text-size': [validators.isAny] }],
			'text-color': [
				{
					text: [textColorValidator],
				},
			],
		},
	},
} as const;

export const twMerge = extendTailwindMerge(twMergeConfig);

// regex to remove instances of more than one space and newlines
const multiSpaceNewLineRegex = /\s\s+/g;
export const removeDuplicateSpacesAndNewlinesFromTemplateLiterals = (str: string) =>
	str.replace(multiSpaceNewLineRegex, ' ');

const cnFn = (...inputs: ClassValue[]): string => {
	if (typeof inputs[0] === 'string' && inputs.length === 1) {
		devWarn(singleArgWarningMessage);
		devWarn(`cn("${inputs[0]}")`);

		return inputs[0];
	}

	return twMerge(clsx(inputs));
};

/**
 * cn is a utility function to efficiently merge Tailwind CSS classes in JS without style conflicts.
 * while also allowing for conditional classes via the clsx package.
 *
 * @returns \{string\} A string of Tailwind CSS classes. This can be used in any component that accepts a className prop.
 *
 * ```tsx
 * import { cn } from '@change-corgi-ds-v2/utils/cn'
 *
 * const Component = ({ className, ...props }) => (
 *  <div className={cn('text-red-500', className)} {...props} />
 * )
 *
 * // or
 *
 * const Component = ({ className, ...props }) => (
 *  <div className={cn('text-red-500', { 'text-blue-500': true }, className)} {...props} />
 * )
 * ```
 */
export const cn = flow(cnFn, removeDuplicateSpacesAndNewlinesFromTemplateLiterals);
