import { isDevelopment } from '../env/env';

type LogFnType = (message: null | number | object | string | undefined) => void;

/**
 * Logs a warning on the console only if the environment is not production
 */
export const devWarn: LogFnType = (message) => {
	if (isDevelopment()) {
		console.warn(message);
	}
};

/**
 * Logs a regular message on the console only if the environment is not production
 */
export const devLog: LogFnType = (message) => {
	if (isDevelopment()) {
		console.log(message);
	}
};

/**
 * Logs an error on the console only if the environment is not production
 */
export const devError: LogFnType = (message) => {
	if (isDevelopment()) {
		console.error(message);
	}
};
